@font-face {
  font-family: 'Roboto-Black';
  src: url("../fonts/roboto/Roboto-Black.eot");
  src: url("../fonts/roboto/Roboto-Black.eot") format("embedded-opentype"),
  url("../fonts/roboto/Roboto-Black.woff") format("woff"),
  url("../fonts/roboto/Roboto-Black.ttf") format("truetype"),
  url("../fonts/roboto/Roboto-Black.svg") format("svg");
}

@font-face {
  font-family: 'Roboto-Light';
  src: url("../fonts/roboto/Roboto-Light.eot");
  src: url("../fonts/roboto/Roboto-Light.eot") format("embedded-opentype"),
  url("../fonts/roboto/Roboto-Light.woff") format("woff"),
  url("../fonts/roboto/Roboto-Light.ttf") format("truetype"),
  url("../fonts/roboto/Roboto-Light.svg") format("svg");
}

@mixin servicesArrow($widthArrow) {
  .the-arrow-right {
    width: $widthArrow;
    position: relative;
    transition: width 0.4s ease-in-out;

    .shaft {
      background-color: #bbca2c;
      display: block;
      height: 1.5px;
      width: $widthArrow;
      position: relative;
      transition: width 0.4s ease-in-out;
      will-change: transform;

      &:before,
      &:after {
        width: 4px;
        background-color: #bbca2c;
        content: '';
        display: block;
        height: 1.5px;
        position: absolute;
        top: 0;
        right: 0;
        transition: width 0.4s ease-in-out;
      }

      &:before {
        transform: rotate(40deg);
        transform-origin: top right;
      }

      &:after {
        transform: rotate(-40deg);
        transform-origin: bottom right;
      }
    }
  }
}

body {
  margin: 0;
  padding: 0;
}

.icon-menu {
  top: 20px;
  height: 60px;
  width: 90px;
  padding: 20px;
  z-index: 80;
  position: fixed;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hp-icon-logo {
  position: fixed;
  right: 20px;
  top: 20px;
  pointer-events: auto;
  cursor: pointer;
  z-index: 80;

  img {
    width: 55px;
  }
}

.logo-incograf {
  position: fixed;
  right: 20px;
  bottom: 20%;
  pointer-events: auto;
  cursor: pointer;
  z-index: 80;

  img {
    width: 35px;
  }
}

.error-content {
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100vh;
  background-color: #4d4d4d;

  .error-number {
    color: white;
    font-size: 3vw;
    font-family: 'Roboto-Light';
    margin-bottom: 2rem;
  }

  .error-message {
    position: relative;

    &.open {
      @include servicesArrow(75%);
    }

    @include servicesArrow(0);

    .text {
      color: #bbca2c;
      font-weight: 700;
      font-size: 3.5vw;
      text-transform: none;
      font-family: 'Roboto-Black';
      margin: 0;
    }

    .the-arrow-right {
      position: absolute !important;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .description {
    color: white;
    font-size: 1.5vw;
    font-family: 'Roboto-Light';
    @include servicesArrow(40px);
    cursor: default;

    a {
      text-decoration: none;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 2rem;
    }

    .link-text {
      padding-right: 10px;
      color: white;
      font-size: 1vw;
      font-family: 'Roboto-Black';
      cursor: pointer;
    }
  }
}


@media only screen and (max-width: 1200px) {
  .error-content {
    .error-number {
      font-size: 7vw;
    }

    .error-message {
      .text {
        font-size: 6vw;
      }
    }

    .description {
      font-size: 2vw;

      .link-text {
        font-size: 1.5vw;
      }
    }

  }
}

@media only screen and (max-width: 992px) {
  .error-content {
    .error-number {
      font-size: 8vw;
    }

    .error-message {
      .text {
        font-size: 7vw;
      }
    }

    .description {
      font-size: 3vw;

      .link-text {
        font-size: 2.5vw;
      }
    }

  }
}

@media only screen and (max-width: 576px) {
  .error-content {
    .error-number {
      font-size: 10vw;
    }

    .error-message {
      .text {
        font-size: 9vw;
      }
    }

    .description {
      font-size: 5vw;

      .link-text {
        font-size: 4.5vw;
      }
    }
  }
}

@media only screen and (min-width: 1900px) {
  .error-content {
    .error-number {
      font-size: 70px;
    }

    .error-message {
      .text {
        font-size: 70px;
      }
    }

    .description {
      font-size: 40px;

      .link-text {
        font-size: 20px;
      }
    }
  }
}

